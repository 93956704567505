var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-5 ma-0 main-table rounded-0 table-custom-header",attrs:{"dense":"","fixed-header":"","hide-default-footer":"","expanded":_vm.expandedData,"height":"70vh","header-props":{ sortIcon: 'mdi-chevron-up' },"headers":_vm.headerTable,"items":_vm.tabTitle === 'On Hand' ? _vm.inventoryDataFilteredSellStatus : _vm.items,"loading":_vm.tableIsLoading,"server-items-length":_vm.totalItems},on:{"update:expanded":function($event){_vm.expandedData=$event},"update:options":_vm.dispatchSortColumn},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._t("expandedTable",null,{"item":item})],2)]}},_vm._l((_vm.headerTable),function(header,index){return {key:`header.${header.value}`,fn:function(){return [_c('div',{key:header.value},[(header.value === 'All')?_c('v-checkbox',{staticClass:"pa-0 ma-0 checkbox",attrs:{"color":"background","input-value":_vm.flagAllSelect,"hide-details":""},on:{"click":_vm.flagAllChange}}):_c('div',{class:[
          _vm.selectedHeader.first === `${index}` || _vm.selectedHeader.last == `${index}`
            ? 'selected-header'
            : '',
          header.multiSortEffect ? 'multiSortEffect' : '',
        ],on:{"click":function($event){if(!$event.ctrlKey)return null;_vm.switchColumns && _vm.selectHeader(index)}}},[_c('span',[_vm._v(_vm._s(header.text))])])],1)]},proxy:true}}),{key:"item",fn:function({ item, index }){return [_c('tr',{staticClass:"transparent",on:{"click":[function($event){if(!$event.ctrlKey)return null;_vm.isNotSOLDOrEXPIRE(item) && !item.newItem && _vm.clickRow(item, index, $event)},function($event){if(!$event.shiftKey)return null;_vm.isNotSOLDOrEXPIRE(item) && !item.newItem && _vm.multiCheckRow(index)}]}},_vm._l((_vm.headerTable),function(header,key){return _c('td',{key:key,class:item.select ? '' : 'dark-blue'},[(
            header.value === 'All' ||
            header.value === 'EquipmentID' ||
            header.value === 'isFlaggedEnabled'
          )?_c('RowStatic',{attrs:{"header":header,"item":item,"index":index,"items":_vm.items,"expandedData":_vm.expandedData,"expandedTable":_vm.expandedTable,"filters":_vm.filters},on:{"itemChanges":_vm.itemChanges,"clickColumn":_vm.clickColumn,"checkRow":_vm.checkRow}}):(!item.select)?_c('span',{staticClass:"ma-0 rounded-0"},[_vm._v(" "+_vm._s(item[header.value]))]):_c('Row',{attrs:{"header":header,"item":item,"index":index,"items":_vm.items,"expandedData":_vm.expandedData,"expandedTable":_vm.expandedTable,"filters":_vm.filters},on:{"itemChanges":_vm.itemChanges,"clickColumn":_vm.clickColumn,"checkRow":_vm.checkRow}})],1)}),0)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }