<template>
  <right-panel>
    <v-row class="pa-1 ma-0">
      <v-col cols="12">
        <!-- HEADER -->
        <v-row
          justify="center"
          class="primary lighten-1 text-center ma-1 rounded"
        >
          <v-col
            align-self="center"
            cols="3"
            v-for="value in title_panel"
            :key="value"
          >
            <span
              class="text-h6 dark_blue--text font-weight-bold text-center ma-1 rounded"
              >{{ value }}</span
            >
          </v-col>
        </v-row>
        <!-- VALUES -->
        <v-row class="text-left pa-1">
          <v-col cols="12" class="pa-0">
            <v-row
              v-for="{ name, value } in content_panel"
              :key="value"
              justify="space-around"
              class="pa-0"
            >
              <v-col cols="6" class="my-auto">
                <span class="text-subtitle-2 primary--text font-weight-bold">{{
                  name
                }}</span>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  class="ma-0 rounded-0 text-subtitle-2"
                  background-color="dark_blue"
                  readonly
                  full-width
                  hide-details
                  dense
                  color="primary"
                  :dark="true"
                  :value="value"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- TABLE -->

        <v-row>
          <v-col>
            <v-data-table
              :headers="INBOUND_PANEl_HEADER"
              :items="data.table_data"
              :hide-default-footer="true"
              :header-props="{ sortIcon: 'mdi-chevron-up' }"
              hide-details
              fixed-header
              dense
              sort-by="status_id"
              :sort-desc="true"
              class="elevation-5 ma-0 pa-0 table-inbound-panel text--md rounded-0"
              :loading="statusTableLoader"
              loading-text="Loading... Please wait"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block color="primary" @click="closeInboundStatus"
              >Close</v-btn
            >
          </v-col>
        </v-row>
        <div class="py-5" v-if="orderDetailsFlag">
          <v-progress-linear indeterminate />
        </div>
        <v-expansion-panels
          v-else
          class="expansion-panel py-1"
          :value="expandedValues"
          multiple
          popout
        >
          <v-expansion-panel class="py-1 dark-blue">
            <v-expansion-panel-header class="pa-0 px-1 dark-blue">
              <v-card elevation="0" class="pa-0 fill-height dark-blue">
                <v-list-item-content
                  class="ma-0 pa-0 d-flex fill-height text-center"
                >
                  <v-list-item-title
                    class="ma-0 primary--text font-weight-black text-h5"
                    tag="span"
                  >
                    <template> Order Details </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-row class="py-5">
                <v-col cols="6" class="elevation-5 pa-1">
                  <CardDetails :fields="fields[0]" :obj="orderData">
                    <template v-slot:[`value_vit_vendorTotal`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{ item ? Number(item).toFixed(2) : "" }}
                      </span>
                    </template>
                    <template v-slot:[`value_GateInDepot`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{
                          item ? parseFromIsoToFormat(item, "yyyy-LL-dd") : ""
                        }}
                      </span>
                    </template>
                    <template v-slot:[`value_gateOut`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{
                          item ? parseFromIsoToFormat(item, "yyyy-LL-dd") : ""
                        }}
                      </span>
                    </template>
                  </CardDetails>
                </v-col>
                <v-col cols="6" class="elevation-5 pa-1">
                  <CardDetails :fields="fields[1]" :obj="orderData">
                    <template v-slot:[`value_sent`]="{ item }">
                      <v-icon v-if="item == 'Y' || item == 'R'" color="green"
                        >mdi-checkbox-marked</v-icon
                      >

                      <v-icon v-if="item == 'N'" color="red"
                        >mdi-minus-box</v-icon
                      >
                    </template>
                    <template v-slot:[`value_date_given`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{
                          item ? parseFromIsoToFormat(item, "yyyy-LL-dd") : ""
                        }}
                      </span>
                    </template>
                    <template v-slot:[`value_release_date`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{
                          item ? parseFromIsoToFormat(item, "yyyy-LL-dd") : ""
                        }}
                      </span>
                    </template>
                    <template v-slot:[`value_salePrice`]="{ item }">
                      <span class="font-weight-thin font-italic">
                        {{ item ? Number(item).toFixed(2) : "" }}
                      </span>
                    </template>
                  </CardDetails>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </right-panel>
</template>

<script>
import RightPanel from "@/layout/RightPanel";
import { INBOUND_PANEl_HEADER } from "@/helpers/mocks/inventory.js";
import {
  get_EOO_sub_details,
  get_inventory_details,
} from "@/helpers/api/Orders";
import { mapState, mapActions } from "vuex";
import axios from "axios";
import _ from "lodash";
import XLSX from "xlsx";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import CardDetails from "@/components/General/CardDetails.vue";
import { processSALDates } from "@/helpers/utils/index.js";

export default {
  components: {
    RightPanel,
    CardDetails,
  },
  async created() {
    this.data = { ...this.inboundPanelData };
    this.orderDetailsFlag = true;
    let result = await Promise.all([
      get_EOO_sub_details(this.inboundPanelData.EquipmentID),
      get_inventory_details(this.inboundPanelData.EquipmentID),
    ]);
    this.orderDetailsFlag = false;
    this.objOrder = result[0].data[0];
    this.inventoryDetails = result[1].data[0];
  },
  computed: {
    ...mapState({
      inboundPanelData: (state) => state.moduleInventory.inboundPanelData,
      inboundPanelFlag: (state) => state.moduleInventory.inboundPanelFlag,
    }),
    title_panel() {
      let { EquipmentID, Size, Type, MfgYear } = this.data;
      return [EquipmentID, Size, Type, MfgYear];
    },
    content_panel() {
      let {
        InboundStatusCode,
        InboundStatusDate,
        InboundStatusIdleDaysCalculated,
        InboundStatusLocation,
      } = this.data;

      return [
        { name: "Current Status", value: InboundStatusCode },
        { name: "Status Date", value: InboundStatusDate },
        { name: "Dwell", value: InboundStatusIdleDaysCalculated },
        { name: "Current Location", value: InboundStatusLocation },
      ];
    },
    table_panel_example() {
      return this.data.table_data;
    },
    orderData() {
      let { GateInDepot, MRComments } = this.objOrder;
      console.log("this.inventoryDetails", this.inventoryDetails);

      let order_sal_ref =
        this.inventoryDetails && this.inventoryDetails.docDate
          ? processSALDates([{ date: this.inventoryDetails.docDate }], "US")[0]
              .header
          : "";

      return {
        GateInDepot,
        MRComments,
        order_sal_ref,
        ...this.inventoryDetails,
      };
    },
  },
  data() {
    return {
      INBOUND_PANEl_HEADER,
      data: {},
      statusTableLoader: false,
      fields: [
        [
          {
            text: "Gate In",
            value: "GateInDepot",
          },
          { text: "M&R Comments", value: "MRComments" },
          { text: "Gate Out", value: "gateOut" },
          { text: "Bill of Sale / Order Ref", value: "order_ref" },
          { text: "Customer", value: "customer_organization_descr" },
          { text: "Order Total", value: "vit_vendorTotal" },
          { text: "Order SAL Ref", value: "order_sal_ref" },
        ],
        [
          { text: "Bill of Sale sent", value: "sent" },
          { text: "Depot", value: "depot_code" },
          { text: "Invoice Num", value: "invoice_number" },
          { text: "Invoice Date", value: "date_given" },
          { text: "Invoice Amount", value: "salePrice" },
          { text: "Release Ref", value: "release_ref" },
          { text: "Release Date", value: "release_date" },
          { text: "Release Comment", value: "release_comment" },
        ],
      ],
      objOrder: {
        GateInDepot: "-",
        MRComments: "-",
        gateOut: "-",
        invoiceNum: "-",
        date_given: "-",
        salePrice: "-",
        nereus_no: "-",
        customer_organization_descr: "-",
        vit_vendorTotal: "-",
        vit_vendorTotal: "-",
        sent: "-",
        depot_code: "-",
        release_ref: "-",
        release_date: "-",
        release_comment: "-",
      },
      orderDetailsFlag: false,
      inventoryDetails: null,
      expandedValues: [0],
    };
  },
  async mounted() {
    this.loadData();
  },

  watch: {
    async inboundPanelData() {
      this.loadData();
    },
  },

  methods: {
    parseFromIsoToFormat,
    ...mapActions([
      "moduleInventory/showInboundStatus",
      "moduleInventory/closeInboundStatus",
      "moduleInventory/getStatusTableHistory",
    ]),
    async loadData() {
      this.data = {};
      this.statusTableLoader = true;

      let remapRoot = await this["moduleInventory/getStatusTableHistory"]({
        EquipmentID: this.inboundPanelData.EquipmentID,
      });
      this.data = { ...remapRoot, ...this.inboundPanelData };
      this.orderDetailsFlag = true;
      let result = await get_inventory_details(
        this.inboundPanelData.EquipmentID,
      );

      this.orderDetailsFlag = false;

      this.inventoryDetails = result.data[0];
      this.statusTableLoader = false;
    },
    closeInboundStatus() {
      this["moduleInventory/closeInboundStatus"]();
    },
    getInboundStatuses() {
      const token = localStorage.getItem("access_token");

      const corsAnywhere = "https://corsanywhere-vjuqlq6gsq-wl.a.run.app";
      let apiGatewayUrlRoot =
        process.env.VUE_APP_ENV == "dev"
          ? `${corsAnywhere}/${process.env.VUE_APP_API_PATH_GATEWAY}/v1`
          : `${process.env.VUE_APP_API_PATH_GATEWAY}/v1`; // NEW GATEWAY URL

      return axios({
        method: "GET",
        headers: { authorization: token },
        url: `${apiGatewayUrlRoot}/vendor/inbound`,
        // url: `http://localhost:9393/v1/vendor/inbound`,
      })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          return err;
        });
    },
    exportTableAll() {
      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      console.log("this.data", this.data);

      // const mergedJson = { ...this.itemData, ...this.data.table_data };
      let mergedJson = [];

      for (const item of this.data.table_data) {
        mergedJson.push({ ...this.itemData, ...item });
      }

      console.log("mergedJson", mergedJson);

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(mergedJson);
      // let filename = `status_hist_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
      let filename = `stat_his_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      // console.log(filename);

      XLSX.utils.book_append_sheet(wb, ws, filename);

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, filename + ".xlsx", { compression: true });
    },
  },
};
</script>

<style scoped>
/* >>> .hideme {
  display: none;
} */
</style>

<style lang="scss">
.table-inbound-panel {
  width: 100%;

  tr {
    background-color: #262737 !important;
  }

  td {
    border: none !important;
    border-bottom: solid thin #303030 !important;
    border-top: solid thin #303030 !important;
  }

  button {
    border: solid thin #262737 !important;
    font-size: small !important;
    color: #3284f4 !important;
  }
  .item-field {
    border: none;
  }

  .v-data-table__wrapper > table > thead > tr > th {
    font-size: medium !important;
    padding: 0;
    font-weight: 900;
    text-align: center;
  }
}
</style>
