<template>
  <v-tooltip
    v-if="header.value === 'All'"
    :disabled="isNotSOLDOrEXPIRE(item)"
    top
    color="primary"
    max-width="150px"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on" class="ma-auto pa-0">
        <v-checkbox
          class="pa-0 ma-auto cb-first-no-scope"
          hide-details
          open-on-click
          id="checkbox"
          :input-value="item.select"
          :disabled="!isNotSOLDOrEXPIRE(item) || item.newItem"
          @click="checkRow(item, index, $event)"
          dense
        ></v-checkbox>
      </span>
    </template>
    <span class="text-subtile-2">{{
      `Inventory in Status ${item.Status} may not be selected for edit.`
    }}</span>
  </v-tooltip>
  <v-checkbox
    v-else-if="header.value === 'isFlaggedEnabled'"
    class="pa-0 ma-auto checkbox"
    hide-details
    open-on-click
    :disabled="flaggedInstructionsByEquipmentIDData === undefined"
    :input-value="
      item.isFlaggedEnabled === 'Y' || item.isFlaggedEnabled === 'true'
        ? true
        : false
    "
    @click="setFlaggedWithInstructions"
    dense
  ></v-checkbox>
  <v-text-field
    v-else-if="header.value === 'EquipmentID'"
    v-model="item[header.value]"
    outlined
    single-line
    class="ma-0 rounded-0 text-center"
    :class="item.select && isInputDisable ? 'input-disable' : ''"
    full-width
    :style="{ width: 'max-content' }"
    hide-details
    dense
    color="#0000"
    :dark="true"
    :readonly="isInputDisable || !item.select"
    :prepend-inner-icon="
      this.expandedTable && this.header.value === 'EquipmentID'
        ? 'mdi-arrow-expand'
        : ''
    "
    @input="valueText(item, header.value)"
    @click:prepend-inner="$emit('clickColumn', { item, index })"
  >
    <template v-if="header.value === 'EquipmentID'" v-slot:append class="pa-0">
      <v-icon dense @click="showInboundStatus(item)"
        >mdi-information-outline</v-icon
      >
    </template>
  </v-text-field>
</template>

<script>
import { table_mixin } from "@/plugins/mixin-inventory.js";

import { mapState, mapActions } from "vuex";
export default {
  mixins: [table_mixin],
  props: ["header", "item", "index", "items", "expandedTable", "filters"],
  computed: {
    ...mapState({
      flaggedInstructions: (state) => state.moduleInventory.flaggedInstructions,
    }),
    flaggedInstructionsByEquipmentIDData() {
      return this.flaggedInstructions.filter((obj) => {
        return obj.EquipmentID === this.item.EquipmentID;
      })[0];
    },
  },
  methods: {
    ...mapActions([
      "updateDataTable",
      "moduleInventory/showInboundStatus",
      "moduleInventory/getFlaggedInstructionsByEquipmentID",
      "moduleInventory/flagInstructions",
    ]),
    showInboundStatus(item) {
      this["moduleInventory/showInboundStatus"](item);
    },
    checkRow(item, index, $event) {
      this.$emit("checkRow", { item, index, event: $event });
    },
    async setFlaggedWithInstructions() {
      if (this.flaggedInstructionsByEquipmentIDData) {
        let saleInstructions = { ...this.flaggedInstructionsByEquipmentIDData };
        saleInstructions.Enabled =
          this.flaggedInstructionsByEquipmentIDData.Enabled === "Y" ? "N" : "Y";

        await this["moduleInventory/flagInstructions"]([saleInstructions]);

        this.updateDataTable({ filters: this.filters });
      }
    },
  },
};
</script>

<style></style>
