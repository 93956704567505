<template>
  <modal width="30%" :show="show" title="Save Filter">
    <template v-slot>
      <v-col class="pa-3">
        <v-radio-group v-model="action" hide-details class="pa-0 ma-0">
          <v-row class="ma-0 pa-0">
            <v-col
              v-for="(option, index) in options"
              :key="option"
              :value="option"
            >
              <v-radio
                class="elevation-5 radio-button"
                :disabled="index === 1 && name === undefined"
              >
                <template v-slot:label>
                  <div>
                    <span class="primary--text " v-if="option == 'create'"
                      >Create new filter</span
                    >
                    <span class="primary--text" v-else>Update filter</span>
                  </div>
                </template></v-radio
              >
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
      <v-col class="pa-1">
        <v-text-field
          class="text-center search-text pa-0"
          hide-details
          type="text"
          v-model="filterName"
          outlined
          dense
        >
          <template v-slot:label>
            Filter name
          </template>
        </v-text-field>
      </v-col>
    </template>

    <template v-slot:actions>
      <v-btn class="elevation-5" color="primary" text @click="save">
        Save
      </v-btn>
      <v-btn class="elevation-5" color="primary" text @click="closeModal">
        cancel
      </v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: ["show", "name", "reset"],
  watch: {
    name() {
      this.action === 0
        ? (this.filterName = "")
        : (this.filterName = this.name);
    },
    action() {
      this.action === 0
        ? (this.filterName = "")
        : (this.filterName = this.name);
    },
  },
  data() {
    return {
      filterName: "",
      action: 0,
      options: ["create", "update"],
    };
  },
  methods: {
    closeModal() {
      this.$emit("showModal", false);
      this.filterName = "";
      this.action = 0;
    },
    save() {
      this.$emit("saveFilter", { option: this.action, name: this.filterName });
      this.action = 0;
    },
  },
};
</script>

<style scoped>
.radio-button {
  width: fit-content !important;
  margin: auto;
  padding: 1px 5px;
}
</style>
