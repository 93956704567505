<template>
  <modal width="50%" :show="show" title="Set Up Grid Header">
    <template v-slot>
      <v-row class="ma-0 pa-0" justify="space-around">
        <v-col cols="6" class="ma-0">
          <v-text-field
            ref="name"
            class="text-center ma-0"
            type="text"
            v-model="nameLayout"
            :rules="[rules.required, rules.min5]"
            outlined
            dense
          >
            <template v-slot:label> Name </template>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-menu offset-y max-height="40%">
            <template v-slot:activator="{ on }">
              <v-btn
                color="dark-blue"
                class="lighten-2 rounded-0"
                v-on="on"
                block
              >
                <span>Saved Grid Headers</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(header, index) in savedHeaders"
                :key="index"
                dense
                :style="
                  header.name === selectHeader.name
                    ? 'background-color:#303030 !important'
                    : ''
                "
              >
                <v-list-item-title
                  @click="loadHeader(header)"
                  style="cursor: default"
                  >{{ header.name }}
                </v-list-item-title>
                <v-list-item-icon
                  @click="deleteHeader(header.id)"
                  style="cursor: pointer"
                >
                  <v-icon color="error" small>mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn color="dark-blue" class="lighten-2" @click="clear" block>
            <span> clear</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="pa-0">
          <draggable
            :list="header"
            :animation="200"
            class="row ma-0 pa-0 cursor-card"
            ghost-class="moving-card"
          >
            <v-col
              cols="4"
              class="ma-0 pa-2"
              v-for="(value, $index) in header"
              :key="$index"
              v-show="value.value !== 'All'"
            >
              <v-card
                class="
                  ma-auto
                  pa-2
                  text-center
                  rounded-0
                  elevation-5
                  card-border
                "
                outlined
                color="#20212E"
              >
                <span class="text-center ma-1 white--text">
                  {{ value.title }}
                </span>
              </v-card>
            </v-col>
          </draggable>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="save"
            block
            :disabled="valid"
          >
            {{ selectHeader.name ? "Update" : "Save" }}
          </v-btn>
        </v-col>
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="showModal(false)"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import Draggable from "vuedraggable";
import { header } from "@/helpers/mocks/inventory.js";
import {
  get_header,
  create_header,
  update_header,
  delete_header,
} from "@/helpers/api/indexv2.js";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Modal,
    Draggable,
  },
  props: ["show"],
  async created() {
    this.header = localStorage.getItem("header")
      ? JSON.parse(localStorage.getItem("header"))
      : [...header];
  },
  data() {
    return {
      header: [],
      nameLayout: "",
      savedHeaders: [],
      selectHeader: {},
      rules: {
        required: (value) => !!value || "Required.",
        min5: (value) => {
          const pattern = /^(?=.{6,24}$)\w+(?: \w+)*$/;
          return pattern.test(`${value}`) || "Min 6, Alphanumeric";
        },
      },
      valid: true,
    };
  },
  computed: {
    ...mapState(["headerGridValues", "resetHeader"]),
  },
  watch: {
    header() {
      this.createHeaderGrid(this.header);
    },
    resetHeader() {
      if (this.resetHeader) {
        this.header = [...header];
        this.setResetHeader(false);
      }
    },
    nameLayout() {
      this.valid = !this.$refs.name.validate();

      //   nameLayout() {
      // console.log(this.$refs.name);
      // console.log(this.$refs.name.validate());
    },
  },
  methods: {
    ...mapMutations(["setSnackbar", "setResetHeader"]),
    ...mapActions(["createHeaderGrid"]),
    save() {
      this.selectHeader.name ? this.updateHeader() : this.saveHeader();
    },
    async updateHeader() {
      this.showModal(false);
      this.$store.commit("setSpinnerShow", true);
      try {
        let { message, pass } = await update_header({
          id: this.selectHeader.id,
          name: this.nameLayout,
          headers_json: JSON.stringify(this.header),
        });
        if (pass) {
          try {
            let { data } = await get_header();

            this.savedHeaders = data.length ? data : [];
          } catch (error) {
            console.log(error);
          }
          this.setSnackbar({
            text: message,
            status: "success",
            show: "showNav",
          });

          this.$store.commit("setSpinnerShow", false);
        } else {
          this.setSnackbar({
            text: message,
            status: "danger",
            show: "showNav",
          });
          this.showModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveHeader() {
      this.showModal(false);
      this.$store.commit("setSpinnerShow", true);
      try {
        let { msg, pass } = await create_header({
          name: this.nameLayout,
          headers_json: JSON.stringify(this.header),
        });
        if (pass) {
          try {
            let { data } = await get_header();

            this.savedHeaders = data.length ? data : [];
          } catch (error) {
            console.log(error);
          }
          this.setSnackbar({
            text: msg,
            status: "success",
            show: "showNav",
          });

          this.nameLayout = "";
          this.$store.commit("setSpinnerShow", false);
        } else {
          this.setSnackbar({
            text: msg,
            status: "danger",
            show: "showNav",
          });

          this.showModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    loadHeader({ name, id, headers_json }) {
      this.selectHeader = { name, id };
      this.header = JSON.parse(headers_json);
      this.nameLayout = name;
    },
    async deleteHeader(id) {
      this.showModal(false);
      this.$store.commit("setSpinnerShow", true);
      try {
        let { msg, pass } = await delete_header({
          id,
        });
        if (pass) {
          if (id === this.selectHeader.id) {
            this.header = [...header];
            this.clear();
          }
          try {
            let { data } = await get_header();

            this.savedHeaders = data.length ? data : [];
          } catch (error) {
            console.log(error);
          }

          this.setSnackbar({
            text: msg,
            status: "success",
            show: "showNav",
          });

          this.$store.commit("setSpinnerShow", false);
        } else {
          this.setSnackbar({
            text: msg,
            status: "danger",
            show: "showNav",
          });
        }
        this.showModal(true);
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.selectHeader = {};
      this.nameLayout = "";
      this.$refs.name.reset();
    },
    showModal(value) {
      this.$emit("showModalHeader", value);
    },
  },
};
</script>

<style scoped>
.moving-card {
  opacity: 0.5;
  background-color: #191a31;
  border: thin solid #3284f4;
  cursor: move;
}
button {
  border: thin #20212e solid;
}

.card-border {
  border-color: #3284f4 !important;
}

.cursor-card {
  cursor: move;
}
</style>
