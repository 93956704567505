<template>
  <v-col cols="12" class="pa-0 ma-0">
    <v-container class="max-width d-flex align-center pa-0 px-2" fluid>
      <v-row class="pa-2 ma-0 justify-space-between">
        <v-col cols="3" class="pa-0" />
        <v-col cols="5" class="d-flex align-center justify-center pa-0">
          <v-pagination
            :value="optionsTable.page"
            :length="totalPages"
            :total-visible="7"
            color="primary"
            @input="setPage"
            class="table-pagination"
          ></v-pagination>
          <!-- style="background-color:#20212E" -->
        </v-col>
        <v-col cols="3" class="d-flex align-center pa-0 ma-0">
          <v-row class="pa-0 ma-0 align-center">
            <v-col class="pa-0 ma-0 align-center d-flex" cols="8">
              <v-row class="pa-0 ma-0">
                <v-col
                  cols="7"
                  class="pa-0 ma-0 align-center justify-end d-flex text-lg-overline"
                  style="font-size: xx-small !important"
                >
                  <span> Rows per page: </span>
                </v-col>
                <v-col cols="5" class="pa-0 ma-0">
                  <v-select
                    :items="numberPerPageOptions"
                    @change="updateItemsPerPage"
                    :value="optionsTable.itemsPerPage"
                    dense
                    solo
                    hide-details
                    background-color="#20212E"
                    class="select-filter lighten-2 pa-0 pl-1 rounded-3 ma-0 text-center text-lg-caption"
                    :append-icon="''"
                    style="width: fit-content"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pa-0 ma-0 align-center text-right text-lg-overline"
              cols="4"
              style="font-size: xx-small !important"
            >
              <span v-if="totalItems"
                >{{
                  this.optionsTable.itemsPerPage !== "ALL"
                    ? this.optionsTable.itemsPerPage *
                      (this.optionsTable.page - 1)
                    : 0
                }}
                -
                {{
                  this.optionsTable.itemsPerPage !== "ALL"
                    ? this.optionsTable.itemsPerPage * this.optionsTable.page >
                      this.totalItems
                      ? this.totalItems
                      : this.optionsTable.itemsPerPage * this.optionsTable.page
                    : this.totalItems
                }}
                of {{ this.totalItems }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
export default {
  props: ["filters"],
  data() {
    return {
      numberPerPageOptions: [50, 100, 150, 200, 250, "ALL"],
    };
  },
  computed: {
    ...mapState(["totalItems", "totalPages", "optionsTable"]),
  },
  methods: {
    ...mapActions(["updateDataTable"]),
    setPage(value) {
      this.updateDataTable({ key: "setPage", value, filters: this.filters });
    },
    updateItemsPerPage(value) {
      this.updateDataTable({
        key: "setItemsPerPage",
        value,
        filters: this.filters,
      });
    },
  },
};
</script>

<style lang="scss">
.table-pagination {
  & .v-pagination__navigation {
    background: #20212e !important;
  }

  /* For page buttons except the active one */
  & .v-pagination__item:not(.v-pagination__item--active) {
    background: #20212e !important;
  }

  & button {
    font-size: small;
  }

  @media only screen and (min-width: 1904px) {
    & li {
      font-size: medium;
    }
  }
}
</style>
