<template>
  <v-row class="pa-0 ma-0 overflow-y-auto" justify="space-around">
    <v-col class="dark_blue elevation-5 ma-1">
      <span class="text-h6 elevation-3 primary--text ma-3">
        Redelivery Instructions
      </span>

      <v-row class="ma-0 elevation-1 pa-2" align="center">
        <v-col class="pa-0" cols="3">
          <span class="text-subtitle-1"> Depot </span>
        </v-col>
        <v-col class="pa-0 text-center" cols="1">
          <span class="text-subtitle-1"> Amount</span>
        </v-col>
        <v-col class="pa-0">
          <span class="text-subtitle-1"> Details</span>
        </v-col>
      </v-row>
      <v-row
        v-for="({ depot, amount, address }, key) in info"
        :key="key"
        class="ma-0 elevation-1 pa-2"
        align="center"
      >
        <v-col class="pa-0" cols="3">
          <span class="text-subtitle-2"> {{ depot }}</span>
        </v-col>
        <v-col class="pa-0 text-center" cols="1">
          <span class="text-subtitle-2"> {{ amount }}</span>
        </v-col>
        <v-col class="pa-0">
          <span class="text-subtitle-2"> {{ address }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["info"],
};
</script>

<style scoped></style>
