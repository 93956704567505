<template>
  <v-menu
    :close-on-content-click="false"
    :value="openMenu"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
  >
    <template v-slot:activator="{ on }">
      <v-list-item
        v-on="on"
        @click="openMenu = true"
        text
        dense
        active-class="pa-0 "
        class="table-nestedMenu ma-0 rounded-0"
      >
        <v-list-item-title
          class="v-input"
          :class="!isSubMenu ? 'justify-center' : 'align-center'"
          :style="{ minHeight: isSubMenu ? '35px' : '' }"
        >
          {{ name }}
        </v-list-item-title>
        <v-icon v-if="isSubMenu">mdi-chevron-right</v-icon>
        <v-icon small v-else>mdi-chevron-down</v-icon>
      </v-list-item>
    </template>
    <v-list
      color="dark-blue"
      class="ma-0 rounded-0 pa-0"
      style="font-size: 14px"
    >
      <template v-for="(item, index) in menuItems">
        <v-divider
          v-if="item.isDivider"
          :key="index"
          style="border-color: #3284f461"
        />
        <div v-else-if="item.isBtn" :key="index">
          <div v-if="isInputActive">
            <v-row>
              <v-col class="pr-0 mr-0">
                <v-btn
                  @click="saveInput(true)"
                  block
                  color="Success"
                  style="font-size: 0.8em"
                  >Save</v-btn
                >
              </v-col>
              <v-col class="pl-0 ml-0">
                <v-btn @click="saveInput(false)" block style="font-size: 0.8em"
                  >Cancel</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-btn @click="addInput" block color="dark-blue">
              <v-icon dark>mdi-plus-circle</v-icon></v-btn
            >
          </div>
        </div>
        <v-text-field
          v-else-if="item.isInput"
          v-model="newOrgData"
          outlined
          class="ma-0 rounded-0"
          full-width
          hide-details
          dense
          ref="orgData"
          color="#0000"
          :dark="true"
          :key="index"
        ></v-text-field>
        <v-list-item
          class="table-nestedMenu ma-0 rounded-0 pa-0"
          dense
          v-else-if="item.menu"
          :key="index"
          :style="
            item.name === name ? 'background-color:#303030 !important' : ''
          "
        >
          <nested-menu
            :key="index"
            :name="item.name"
            :menu-items="item.menu.data"
            :nameParent="item.menu.nameParent"
            :isInputActive="isInputActive[index]"
            @menuClick="emitClickEvent"
            @addInput="addInput"
            @saveInput="saveInput"
            :isOffsetX="true"
            :isOffsetY="false"
            :isSubMenu="true"
            :model="model"
          />
        </v-list-item>
        <v-list-item
          dense
          v-else
          :key="index"
          :style="{
            height: '35px',
            'background-color': item.name === name ? '#303030' : '',
          }"
          @click="emitClickEvent(item)"
          class="table-nestedMenu ma-0 rounded-0"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import NestedMenu from "@/components/Inventory/NestedMenu.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "nested-menu",
  components: { "nested-menu": NestedMenu },
  props: [
    "name",
    "menuItems",
    "nameParent",
    "isSubMenu",
    "model",
    "isInputActive",
    "isOffsetX",
    "isOffsetY",
  ],
  // async created() {
  //   if (!this.organizations.length) await this.getOrganizations();
  // },
  computed: {
    ...mapState(["organizations"]),
  },
  methods: {
    ...mapActions(["getOrganizations"]),
    saveInput(payload) {
      this.$emit("saveInput", {
        save: payload.save !== undefined ? payload.save : payload,
        inputObj: payload.inputObj ? payload.inputObj : this.$refs.orgData,
        newOrgData: payload.newOrgData ? payload.newOrgData : this.newOrgData,
        indexMenu: payload.indexMenu ? payload.indexMenu : this.$vnode.key,
      });
      this.newOrgData = "";
    },
    emitClickEvent(item) {
      let nameParent = this.nameParent;
      let model = this.model;
      this.$emit("menuClick", { item, nameParent, model });
      this.openMenu = false;
    },
    addInput(index) {
      this.$emit(
        "addInput",
        this.$vnode.data.key ? this.$vnode.data.key : index,
      );
    },
  },
  data: () => ({
    openMenu: false,
    newOrgData: "",
  }),
};
</script>

<style scoped>
.table-nestedMenu {
  min-height: 25px;
}
</style>
