<template>
  <v-container class="elevation-5 pa-1 ma-0 mb-2 dark-blue" ref="panel" fluid>
    <v-row dense class="justify-end align-start">
      <v-col xl="6" md="6" class="pa-1 ma-0">
        <v-row dense class="justify-end align-center pa-2">
          <v-col class="mr-auto pa-0">
            <v-text-field
              class="ma-0"
              hide-details
              type="text"
              v-model="searchField"
              single-line
              outlined
              dense
              clearable
              :prepend-inner-icon="'mdi-magnify'"
              :append-icon="'mdi-filter-variant'"
              @click:append="$emit('setChowFilterPanel')"
              @click:prepend-inner="searchData(true)"
              @click:clear="searchData(false)"
              @keyup.enter="searchData(true)"
            >
              <template v-slot:label
                ><span class="pa-0 ma-0"> Search </span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="justify-end align-center">
          <v-col cols="12" class="mr-auto pa-0">
            <v-chip
              class="ma-1 mb-0 mt-0"
              color="primary"
              small
              close
              text-color="error"
              v-for="(filter, index) in filters"
              :key="index"
              @click:close="$emit('removeFilter', index)"
            >
              <span class="white--text">
                {{ filter.title }} {{ filter.operator }} {{ tagValue(filter) }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>

      <v-col xl="6" md="6" class="pa-0 ma-0 panel-buttons">
        <v-row dense class="justify-end align-center pa-2">
          <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <!-- <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  v-on="on"
                  :disabled="!itemsSelectedWithDepot.length"
                  @click="$emit('showSellInstruccions', true)"
                  block
                >
                  <v-icon dark>mdi-file-cancel</v-icon>
                </v-btn> -->
              </template>
              <span>Clear Flagged</span>
            </v-tooltip>
          </v-col>

          <!-- <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  v-on="on"
                  @click="refreshDepots"
                  block
                >
                  <v-icon dark>mdi-store-alert</v-icon>
                </v-btn>
              </template>
              <span>Refresh Depots</span>
            </v-tooltip>
          </v-col> -->

          <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  v-on="on"
                  :disabled="!itemsSelectedWithDepot.length"
                  @click="$emit('showSellInstruccions', true)"
                  block
                >
                  <v-icon dark>mdi-file-document-edit</v-icon>
                </v-btn>
              </template>
              <span>Sale Instructions</span>
            </v-tooltip>
          </v-col>

          <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  v-on="on"
                  @click="exportTableAll"
                  block
                >
                  <v-icon dark>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Export data</span>
            </v-tooltip>
          </v-col>

          <v-col xl="1" md="1">
            <v-menu offset-y max-height="40%" class="pa-0 ma-0">
              <template v-slot:activator="{ on: onMenu }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on: onTooltip, attrs }">
                    <!-- <v-hover :value="onTooltip"> -->
                    <v-btn
                      color="dark-blue"
                      class="lighten-2 pa-1"
                      v-bind="attrs"
                      v-on="{ ...onMenu, ...onTooltip }"
                      block
                    >
                      <v-icon dark>mdi-folder</v-icon>
                    </v-btn>
                    <!-- </v-hover> -->
                  </template>
                  <span>Saved Filters</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(filter, index) in savedFilters"
                  :key="index"
                  dense
                  :style="
                    filter.name === selectFilter.name
                      ? 'background-color:#303030 !important'
                      : ''
                  "
                >
                  <v-list-item-title
                    @click="addFiltersData(filter)"
                    style="cursor: default"
                    >{{ filter.name }}
                  </v-list-item-title>
                  <v-list-item-icon
                    @click="deleteFilter(filter.id)"
                    style="cursor: pointer"
                  >
                    <v-icon color="error" small>mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col xl="1" class="d-flex" md="1">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  @click="showModalHeader"
                  v-on="on"
                  block
                >
                  <v-icon dark>mdi-grid</v-icon>
                </v-btn>
              </template>
              <span> Set header</span>
            </v-tooltip>
          </v-col>

          <v-col xl="1" class="d-flex" md="1">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  @click="resetHeaderGrid"
                  block
                  v-on="on"
                >
                  <v-icon dark>mdi-grid-off</v-icon>
                </v-btn>
              </template>
              <span> Reset header</span>
            </v-tooltip>
          </v-col>

          <v-col xl="1" class="d-flex" md="1">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  @click="showMultiSortModal"
                  block
                  v-on="on"
                >
                  <v-icon dark>mdi-sort</v-icon>
                </v-btn>
              </template>
              <span> Multi Sort</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row dense class="justify-end align-center">
          <v-col
            xl="2"
            md="3"
            class="pa-0 ma-0 d-flex justify-end"
            v-if="addingRow || changedItems.length"
          >
            <v-btn
              color="#3a6739"
              class="lighten-2 ma-1"
              id="inv-table-action-save "
              block
              :disabled="loading"
              @click="save"
            >
              Save
              <v-icon right dark :style="{ color: '#fff !important' }"
                >mdi-content-save</v-icon
              >
            </v-btn>
            <v-btn
              color="dark-red"
              class="lighten-2 ma-1"
              block
              :disabled="loading"
              @click="cancel"
            >
              Cancel
              <v-icon right dark :style="{ color: '#fff !important' }"
                >mdi-cancel</v-icon
              >
            </v-btn>
          </v-col>
          <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  @click="add"
                  block
                  v-on="on"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add item</span>
            </v-tooltip>
          </v-col>
          <v-col xl="1" md="1" class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="dark-blue"
                  class="lighten-2 pa-1"
                  @click="refresh"
                  block
                  v-on="on"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh data</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { header } from "@/helpers/mocks/inventory.js";
import XLSX from "xlsx";

import _ from "lodash";

export default {
  props: [
    "refreshFlag",
    "addingRow",
    "loading",
    "filters",
    "itemsSelectedWithDepot",
  ],

  computed: {
    ...mapState([
      "inventory",
      "user",
      "inventoryDataFiltered",
      "savedFilters",
      "changedItems",
    ]),
    ...mapState({
      selectedRows: (state) => state.moduleInventory.selectedRows,
    }),
    ...mapGetters(["getItemsPerPage"]),
  },
  async created() {
    await this.getSavedFiltered();
  },
  data() {
    return {
      search: false,
      searchField: "",
      addFlag: false,
      action: "",
      selectFilter: {},
      selectedWithDepot: false,
    };
  },
  watch: {
    filters() {
      if (!this.filters.lenght) this.selectFilter = {};
    },
    addingRow() {
      this.addFlag = this.addingRow;
    },
  },
  methods: {
    ...mapMutations([
      "setInventorySearchFlag",
      "setOptionsTable",
      "setInventorySearchString",
      "setInventoryFilteredColumnFlag",
    ]),
    ...mapActions([
      "filteredList",
      "getSavedFiltered",
      "dispatchDeleteFilter",
      "dispatchSearchEvent",
    ]),
    refreshDepots() {
      this.$emit("addMissingACL");
    },
    addFiltersData(filter) {
      console.log(filter);
      this.selectFilter = filter;
      this.$emit("addFiltersData", filter);
    },
    tagValue(filter) {
      return filter.operator === "BETWEEN"
        ? `${filter.fromDate} AND ${filter.toDate}`
        : filter.value;
    },
    searchData(flag) {
      this.dispatchSearchEvent({ flag, value: this.searchField });
    },
    showMultiSortModal() {
      this.$emit("showMultiSortModal", true);
    },
    resetHeaderGrid() {
      this.$store.commit("setResetHeader", true);
    },
    deleteFilter(id) {
      this.dispatchDeleteFilter(id);
    },

    showModalHeader() {
      this.$emit("showModalHeader", true);
    },
    exportTableAll() {
      const filterOut = [
        "EstDateOfReturn",
        "vish_InboundStatusDate",
        "vish_InboundStatusLocation",
        "vish_InboundStatusCode",
        "vish_InboundStatusIdleDaysCalculated",
        "substep_descr",
        "vts_StepIntoStatusCode",
        "vts_StepIntoCondition",
        "vts_Code",
        "Age",
        "BuyerParty",
        "BuyerPartyId",
        "BuyerPartyOrganization_id",
        "AllocatedToVisibleEntity_id",
        "SoldToVisibleEntity_id",
        "DateAvailable",
        "allocated_to_entity",
        "vish_statusDateGiven",
      ];

      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      let invToExport = this.inventoryDataFiltered
        ? this.inventoryDataFiltered
        : this.inventory;

      try {
        invToExport = invToExport.map((x) => {
          for (let filt of filterOut) {
            delete x[filt];
          }

          return x;
        });
      } catch (error) {
        invToExport = invToExport;
      }

      // console.log("invToExport", invToExport);

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(invToExport);

      var wscols = [];

      for (let i = 0; i < 50; i++) {
        wscols.push({ wch: 15 });
      }

      ws["!cols"] = wscols;

      let filename = `nv_exp_inv_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      // console.log(filename);

      XLSX.utils.book_append_sheet(wb, ws, filename);

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, filename + ".xlsx", { compression: true });
    },
    save() {
      this.addFlag = false;
      this.$emit("save", this.action);
      this.action = "";
    },
    cancel() {
      this.addFlag = false;
      this.$emit("cancel", this.action);
      this.action = "";
    },
    acting() {
      switch (this.action) {
        case "Add":
          this.add();
          break;
      }
    },
    add() {
      this.action = "Add";
      this.addFlag = true;
      this.$emit("addRow", this.addFlag);
    },
    refresh() {
      this.action = "";
      if (!this.refreshFlag) this.$emit("refreshTable");
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-buttons {
  .row {
    gap: 2px;

    .v-btn {
      @include elevation(5, true);
    }
  }
}
</style>
