<template>
  <v-stepper v-model="step" class="pa-0 w-100 fill-height">
    <v-stepper-header class="pa-5" :style="{ height: 'auto' }">
      <v-stepper-step :complete="step > 1" step="1" class="pa-0">
        Set Redelivery Instructions
      </v-stepper-step>
      <v-stepper-step :complete="step > 2" step="2" class="pa-0">
        Depot Confirmation
      </v-stepper-step>
      <v-stepper-step :complete="step > 3" step="3" class="pa-0">
        Sent. Check your email
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1" class="ma-0 pa-0">
        <v-row justify="end" class="py-2 mr-12">
          <v-col cols="auto" class="d-flex justify-center align-center">
            <span class="text-subtitle-1 px-2">Gate By</span>
            <v-checkbox
              class="pa-0 ma-0 checkbox"
              color="primary"
              hide-details
              v-model="gate_by"
              @click="setGateBy"
            ></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-autocomplete
              :items="filteredListCustomers"
              label="Gate Reference"
              color="white"
              dense
              outlined
              :background-color="!gate_by ? 'gray' : '#20212E'"
              :disabled="!gate_by"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24"
              item-text="organization_descr"
              item-value="organization_id"
              v-model="gate_reference"
              @input="instructionsChanged"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-container class="pa-0" fluid :style="{ maxHeight: '40vh' }">
            <v-data-table
              :headers="tableHeaders"
              :items="instruction"
              height="100%"
              dense
              hide-default-footer
              disable-sort
              disable-pagination
              class="elevation-5 ma-0 rounded-0 main-table table-readOnly"
            >
              <template v-slot:item="{ item }">
                <tr :class="!item.check ? 'grey darken-4' : ''">
                  <td v-for="header in headers" :key="header.title">
                    <v-checkbox
                      v-if="header.value === 'actions'"
                      class="pa-0 ma-0 checkbox"
                      color="primary"
                      hide-details
                      v-model="item.check"
                    ></v-checkbox>

                    <v-text-field
                      v-else-if="ENABLE_VALUES.includes(header.value) && item.check"
                      v-model="item[header.value]"
                      outlined
                      single-line
                      background-color="grey darken-4"
                      class="ma-0 px-1 py-0 rounded-2"
                      full-width
                      hide-details
                      dense
                      @input="instructionsChanged"
                    ></v-text-field>
                    <v-text-field
                      v-else-if="
                        ['Remarks', 'Empty_Return_Reference'].includes(header.value) &&
                        item.check
                      "
                      :value="`${item[header.value]}${org != '' ? '= ' + org : ''}`"
                      outlined
                      single-line
                      background-color="grey darken-4"
                      class="ma-0 px-1 py-0 rounded-2"
                      full-width
                      hide-details
                      dense
                      @input="instructionsChanged($event, item, header.value)"
                    ></v-text-field>

                    <span v-else>{{ item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-btn
              class="elevation-5 dark_blue"
              color="primary"
              text
              @click="save"
              :disabled="!dataFilter.length || (gate_by && !gate_reference)"
              block
            >
              Commit Data
              <v-icon dark class="text-h5"> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="2" class="ma-0 pa-0">
        <v-card class="pa-0" style="height: 50vh">
          <v-row class="pa-0 ma-0" justify="end">
            <v-col cols="2" class="pa-0 ma-0 mr-2 d-flex justify-end">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[rules.required, rules.email]"
                solo
                class="ma-0 pa-0 rounded-0"
                full-width
                dense
                background-color="dark_blue"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 d-flex justify-end">
              <v-btn class="primary" block @click="exportTemplate">Export Template</v-btn>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="10" class="ma-auto pa-0">
              <SaleInstructionsConfirmation :info="saleInstructionsConfirmationData" />
            </v-col>
          </v-row>
        </v-card>
        <v-row class="pa-2">
          <v-col cols="2">
            <v-btn class="elevation-5" color="primary" text @click="step = 1" block>
              <v-icon dark class="text-h5"> mdi-chevron-left </v-icon>
              Return
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="elevation-5 dark_blue"
              color="primary"
              text
              :disabled="rules.required(email) !== true || rules.email(email) !== true"
              @click="save"
              block
            >
              Send Email
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="elevation-5 dark_blue"
              color="primary"
              text
              @click="showPreview"
              block
            >
              Preview
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3" class="ma-0 pa-0">
        <v-card class="pa-0" style="height: 50vh">
          <v-row
            class="pa-0 ma-0 overflow-y-auto"
            style="height: 80%"
            justify="space-around"
          >
            <v-col cols="10" class="pa-0">
              <EmailPreviewContainer :previewHtml="emailsPreview[emailsPreviewPag - 1]" />
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col class="pa-0">
              <v-pagination
                v-model="emailsPreviewPag"
                :length="emailsPreview.length"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="pa-2">
          <v-col cols="2">
            <v-btn class="elevation-5" color="primary" text @click="step = 2" block>
              <v-icon dark class="text-h5"> mdi-chevron-left </v-icon>
              Return
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import SaleInstructionsConfirmation from "@/components/Inventory/SaleInstructions/SaleInstructionsConfirmation.vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import XLSXStyle from "xlsx-js-style";
import EmailPreviewContainer from "./SaleInstructions/EmailPreviewContainer.vue";
import { get_flags_instructions } from "../../helpers/api/indexv2";
import _ from "lodash";

export default {
  components: {
    Modal,
    SaleInstructionsConfirmation,
    EmailPreviewContainer,
  },
  // props: ["selectedRows"],
  async created() {
    if (!this.filteredListCustomers.length)
      await this["moduleOrganizations/getsOrganizations"]();
    this.step = 1;
    this.itemsSelected();
  },
  props: ["itemsSelectedWithDepot", "filters"],
  computed: {
    ...mapState(["inventoryDataFiltered"]),
    ...mapState({
      selectedRows: (state) => state.moduleInventory.selectedRows,
      flaggedInstructions: (state) => state.moduleInventory.flaggedInstructions,
    }),
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
    }),
    org() {
      return this.gate_reference
        ? this.filteredListCustomers.filter(
            (x) => x.organization_id == this.gate_reference
          )[0].organization_descr
        : "";
    },

    dataFilter() {
      return this.instruction.filter(({ check }) => {
        if (check) return true;
      });
    },
    templateData() {
      return this.dataFilter.map(
        ({
          EquipmentID,
          Size,
          Type,
          age,
          returnDepot,
          City,
          State,
          Ship_co,
          Instruction,
          Remarks,
          Empty_Return_Reference,
        }) => {
          return {
            "Equipment ID": EquipmentID,
            Size: `${Size}${Type}`,
            Age: age,
            Pool: returnDepot,
            City,
            State,
            "Ship co": Ship_co,
            gate_by: false,
            gate_reference: "",
            Instruction,
            Remarks,
            "Empty Return Reference": Empty_Return_Reference,
          };
        }
      );
    },
    depots() {
      return this.dataFilter.map((value) => value.returnDepot);
    },

    tableHeaders() {
      let headerData = [];

      for (let { value, title } of this.headers) {
        headerData.push({
          text: title,
          align: "center",
          value,
          class: "table-header text-capitalize primary",

          width:
            value === "Size" || value === "Type" || value === "gate_by"
              ? "5%"
              : value === "actions"
              ? "2%"
              : value === "Empty_Return_Reference" || value === "Remarks"
              ? "16%"
              : "9.3%",
        });
      }
      return headerData;
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",

        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      emailsPreview: "",
      emailsPreviewPag: 1,
      email: "",
      step: null,
      instruction: [],
      idItems: [],
      ENABLE_VALUES: ["Ship_co", "Instruction"],
      headers: [
        { title: "Equipment ID", value: "EquipmentID" },
        { title: "Size", value: "Size" },
        { title: "Type", value: "Type" },
        { title: "Return depot", value: "returnDepot" },
        { title: "City", value: "City" },
        { title: "State", value: "State" },
        { title: "Ship co", value: "Ship_co" },
        { title: "Instruction", value: "Instruction" },
        // { title: "Gate By", value: "gate_by" },
        // { title: "Gate Reference", value: "gate_reference" },

        { title: "Remarks", value: "Remarks" },
        { title: "Empty Return Reference", value: "Empty_Return_Reference" },
        { title: " ", value: "actions" },
      ],
      gate_by: false,
      gate_reference: null,
      instructionsChangedValue: false,
      instructionsCommited: false,

      saleInstructionsConfirmationData: {},
    };
  },

  methods: {
    ...mapActions([
      "updateDataTable",
      "moduleInventory/sendDepotTemplate",
      "moduleInventory/flagInstructions",
      "moduleInventory/getFlagsInstructions",
      "moduleInventory/getDepotDetails",
      "moduleOrganizations/getsOrganizations",
    ]),
    ...mapMutations(["setSnackbar", "setSpinnerShow"]),
    setGateBy() {
      this.gate_reference = null;
      this.instructionsChanged();
    },
    async info() {
      let depots = [];
      try {
        let info_address = this.dataFilter.reduce((p, c) => {
          p[c.returnDepot] = p[c.returnDepot] || [];

          p[c.returnDepot].push(c);
          if (!depots.includes(c.returnDepot)) depots.push(c.returnDepot);
          return p;
        }, Object.create(null));

        let promises = await Promise.allSettled(
          depots.map(async (returnDepot) => {
            let response = await this["moduleInventory/getDepotDetails"](returnDepot);

            if (response.length) {
              return {
                amount: info_address[returnDepot].length,
                depot: returnDepot,
                address: response[0].address,
              };
            } else {
              this.setSnackbar({
                text: "Depot value does not have address",
                status: "danger",
                show: "showNav",
              });
            }
          })
        );

        return promises.map(({ status, value }) =>
          status == "rejected"
            ? {
                amount: info_address[returnDepot].length,
                depot: returnDepot,
                address: "Not Found",
              }
            : value
        );
      } catch (error) {
        console.log("info ERROR:", error);
      }
    },
    async showPreview() {
      let response = await this["moduleInventory/sendDepotTemplate"]({
        depots: this.dataFilter,
      });
      this.emailsPreview = response.data.htmls;
      this.step = 3;
    },
    instructionsChanged(value, item, header) {
      if (item) {
        item[header] = value.split(" -")[0];
      }
      if (!this.instructionsChangedValue) this.instructionsChangedValue = true;
    },
    exportTemplate() {
      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      var wb = XLSXStyle.utils.book_new();
      var ws = XLSXStyle.utils.json_to_sheet(this.templateData);

      var wscols = [];
      //A === 65 and 74 === J
      for (let i = 65; i <= 74; i++) {
        let value = String.fromCharCode(i);
        ws[`${value}1`].s = {
          font: {
            bold: true,
          },
          fill: {
            fgColor: {
              rgb: value === "D" ? "ffffff00" : "FF86c5da",
            },
          },
          alignment: {
            horizontal: "center",
            wrapText: false,
          },
        };

        wscols.push({ wch: 20 });
      }

      ws["!cols"] = wscols;

      let filename = `template_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      // console.log(filename);
      XLSXStyle.utils.format_cell;
      XLSXStyle.utils.book_append_sheet(wb, ws, filename);

      /* Trigger Download with `writeFile` */
      XLSXStyle.writeFile(wb, filename + ".xlsx", { compression: true });
    },
    async save() {
      let response;
      switch (this.step) {
        case 1:
          //send this.data

          const org = this.org != "" ? ` - ${this.org}` : "";
          if (!this.instructionsCommited || this.instructionsChangedValue) {
            let dataFilter = this.dataFilter.map((x) => {
              return {
                ...x,
                gate_by: this.gate_by,
                gate_reference: this.gate_reference,
                Remarks: `${x.Remarks}${org}`,
                Empty_Return_Reference: `${x.Empty_Return_Reference}${org}`,
              };
            });
            console.log(dataFilter);
            response = await this["moduleInventory/flagInstructions"](dataFilter);

            this.updateDataTable({ filters: this.filters });
            this.setSpinnerShow(true);
            this.saleInstructionsConfirmationData = await this.info();
            this.setSpinnerShow(false);
          }
          this.instructionsCommited = true;
          this.step = 2;

          break;
        case 2:
          // Send Email
          response = await this["moduleInventory/sendDepotTemplate"]({
            depots: this.dataFilter,
            email: this.email,
          });
          this.emailsPreview = response.data.htmls;

          this.step = 3;
          break;
      }
    },
    async itemsSelected() {
      // this.itemsSelectedWithDepot = _.uniq(this.itemsSelectedWithDepot);

      // Enrich itemsSelectedWithDepot with actual pulled instructions info

      const pulled = this.flaggedInstructions;
      // wait get_flags_instructions().then((x) => x.response);

      this.instruction = [];
      this.itemsSelectedWithDepot.map((item) => {
        let pulledItem = pulled.filter((x) => x.EquipmentID == item.EquipmentID)[0];

        if (pulledItem) {
          this.gate_reference = pulledItem.gate_reference
            ? Number(pulledItem.gate_reference)
            : null;
          this.gate_by = !!this.gate_reference;
        }
        let value = {
          EquipmentID: item.EquipmentID,
          Size: item.Size ? item.Size : "",
          Type: item.Type ? item.Type : "",
          returnDepot: item.Depot ? item.Depot : "",
          City: item.Depot ? item.Depot.substring(0, 5) : "",
          State: item.State ? item.State : "",
          Ship_co: "0001",
          Instruction: "SOLD",
          Remarks: "SALE BOX **BOX ONLY",
          Empty_Return_Reference: "SALE BOX **BOX ONLY",
          age: item.MfgYear,
          check: true,
          Enabled: "Y",
        };

        if (pulledItem) {
          if ("Instruction" in pulledItem) {
            value = {
              EquipmentID: item.EquipmentID,
              Size: item.Size ? item.Size : "",
              Type: item.Type ? item.Type : "",
              returnDepot: item.Depot ? item.Depot : "",
              City: item.Depot ? item.Depot.substring(0, 5) : "",
              State: item.State ? item.State : "",
              Ship_co: pulledItem.Ship_co,
              Instruction: pulledItem.Instruction,
              Remarks: pulledItem.Remarks,
              Empty_Return_Reference: pulledItem.Empty_Return_Reference,
              age: item.MfgYear,
              check: pulledItem.Enabled == "Y" ? true : false,
              Enabled: pulledItem.Enabled,
            };
          }
        }

        this.instruction.push(value);
      });
    },
  },
};
</script>

<style></style>
