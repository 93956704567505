<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-x
    nudge-right="150"
    left
    max-width="290px"
    min-width="auto"
    :disabled_DISABLED="!clicked"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :outlined="!solo ? true : false"
        :solo="solo ? true : false"
        :background-color="solo ? '#20212E' : ''"
        :value="data[keyData]"
        class="ma-0 rounded-0"
        :class="classes"
        full-width
        hide-details
        dense
        color="#0000"
        :dark="true"
        v-on="on"
        :disabled="disabled"
      ></v-text-field>
      <!-- @blur="updateDate" -->
    </template>
    <v-date-picker
      v-model="newDate"
      no-title
      @input="updateDate"
      class="date-picker"
      color="primary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";
export default {
  props: ["data", "keyData", "disabled", "emitEvent", "solo", "clicked"],
  data() {
    return {
      showMenu: false,
      newDate: "",
    };
  },
  computed: {
    classes() {
      let clasess = "";
      clasess += this.disabled ? "input-disable" : "";
      clasess += this.solo ? "mt-1" : "";
      return clasess;
    },
  },
  methods: {
    updateDate() {
      console.log(this.newDate);

      let date = DateTime.fromSQL(this.newDate, {});
      console.log(date);

      this.$set(this.data, this.keyData, date.toFormat("LL/dd/yyyy"));

      date = date.setZone("America/Phoenix", { includeZone: false });
      console.log(date);

      if (this.keyData === "fromDate" || this.keyData === "toDate") {
        this.data[`parse${this.keyData}`] = date.toFormat(
          "yyyy/LL/dd HH:mm:ss",
        );
      } else {
        this.data["parseDate1"] = date.toFormat("yyyy/LL/dd HH:mm:ss");
        this.data["parseDate2"] = date
          .plus({ days: 1 })
          .toFormat("yyyy/LL/dd HH:mm:ss");
      }

      if (this.emitEvent) this.$emit(`${this.emitEvent}`);
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss"></style>
