<template>
  <v-container class="fill-height filter-panel pa-0 ma-0 elevation-5">
    <v-row class="align-content-center align-self-start start-button">
      <v-col class="ma-0 pa-1">
        <v-btn
          color="dark-blue"
          class="lighten-2 rounded-0"
          @click="addFilter"
          block
        >
          <span>Add Filter <v-icon dark>mdi-plus</v-icon></span>
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="d-flex inside-container align-content-start align-start pa-0 flex-wrap elevation-0 overflow-y-auto overflow-x-hidden"
    >
      <v-row
        class="pa-2 ma-0 mb-2 align-center row-filter dark-blue"
        v-for="(filter, index) in filters"
        :key="index"
      >
        <!-- Field -->
        <v-col class="pa-1 ma-0" cols="4">
          <v-select
            :items="headerTitle"
            dense
            full-width
            solo
            background-color="#20212E"
            hide-details
            class="select-filter lighten-2 pa-0 rounded-0 ma-0 elevation-5 text-center"
            v-model="filter.title"
            @input="filterSelected(filter, index)"
            :append-icon="''"
          ></v-select>
        </v-col>
        <!-- Operator -->
        <v-col class="pa-1 ma-0 d-flex align-center" cols="2">
          <v-select
            :items="operatorValues"
            dense
            solo
            full-width
            background-color="#20212E"
            hide-details
            class="select-filter lighten-2 pa-0 rounded-0 ma-0 elevation-5 text-center"
            :append-icon="''"
            v-model="filter.operator"
          >
            <template v-slot:selection="{ item }">
              <span v-if="iconOperator(item)">
                <v-icon dark>
                  {{ iconOperator(item) }}
                </v-icon>
              </span>
              <span v-else>{{ item }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span v-if="iconOperator(item)">
                <v-icon dark>
                  {{ iconOperator(item) }}
                </v-icon>
              </span>
              <span v-else>{{ item }}</span>
            </template>
          </v-select>
        </v-col>
        <!-- Value  -->
        <v-col class="pa-1 ma-0" :cols="index === 0 ? 5 : 3">
          <v-select
            v-if="filter.field === 'Status'"
            :items="statusMenuItems"
            dense
            solo
            full-width
            background-color="#20212E"
            hide-details
            class="select-filter lighten-2 pa-0 rounded-0 ma-0 elevation-5 text-center"
            label="status"
            v-model="filter['value']"
            :append-icon="''"
          ></v-select>
          <v-select
            v-else-if="filter.field === 'isFlaggedEnabled'"
            :items="flaggedValues"
            dense
            solo
            full-width
            background-color="#20212E"
            hide-details
            class="select-filter lighten-2 pa-0 rounded-0 ma-0 elevation-5 text-center"
            label="Flagged"
            v-model="filter['value']"
            :append-icon="''"
          >
          </v-select>
          <date-picker
            v-else-if="DATES.includes(filter.field)"
            :data="filter"
            :solo="true"
            :keyData="filter.operator === 'BETWEEN' ? 'fromDate' : 'value'"
          ></date-picker>
          <v-text-field
            v-else
            hide-details
            dense
            solo
            background-color="#20212E"
            class="ma-0 rounded-0 elevation-5 input-filter text-center"
            full-width
            v-model="filter.value"
          >
          </v-text-field>

          <date-picker
            v-if="filter.operator === 'BETWEEN'"
            :data="filter"
            :solo="true"
            keyData="toDate"
          ></date-picker>
        </v-col>
        <!-- SQL Conector -->
        <v-col
          class="pa-1 ma-0 d-flex align-center"
          cols="2"
          v-if="index !== 0"
        >
          <v-select
            :items="sqlLinkValues"
            dense
            solo
            background-color="#20212E"
            hide-details
            class="select-filter lighten-2 pa-0 rounded-0 ma-0 elevation-5 text-center"
            :append-icon="''"
            v-model="filter.conector"
          >
          </v-select>
        </v-col>
        <!-- Delete icon -->
        <v-col class="pa-1 ma-0" cols="1">
          <v-btn
            class="ma-0 pa-0 btn-delete elevation-0"
            small
            height="100%"
            @click="removeFilter(index)"
          >
            <v-icon dark color="error" small> mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="ma-0 btn-bottom">
      <v-row class="pa-1 ma-0 w-100">
        <v-col class="pa-1">
          <v-btn block color="dark-blue" @click="clearFilters">Clear</v-btn>
        </v-col>
        <v-col class="pa-1">
          <v-btn block color="dark-blue" @click="showModal(true)">Save</v-btn>
        </v-col>
      </v-row>
      <v-row class="pa-1 pt-0 pb-0 ma-0">
        <v-col class="pa-1">
          <v-btn block color="primary" @click="filteredInventoryByColumn"
            >Search</v-btn
          >
        </v-col>

        <v-col class="pa-1 ma-0" cols="1">
          <v-btn
            class="ma-0 pa-0 btn-close elevation-5"
            color="primary"
            small
            block
            height="100%"
            @click="$emit('setChowFilterPanel')"
          >
            <v-icon dark> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <filter-modal
      :show="show"
      :name="filterInfo.name"
      @showModal="showModal"
      @saveFilter="saveFilter"
    ></filter-modal>
  </v-container>
</template>

<script>
import { header } from "@/helpers/mocks/inventory.js";
import { DATES } from "@/helpers/mocks/dates.js";
import FilterModal from "@/components/Inventory/FilterModal";
import { DateTime } from "luxon";
import DatePicker from "@/components/Inventory/DatePicker.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { update_saved_filter } from "@/helpers/api/indexv2.js";
export default {
  props: ["filterInfo", "filters"],
  components: {
    FilterModal,
    DatePicker,
  },

  data() {
    return {
      filterName: "",
      action: "",
      operatorsValue: [
        { operator: "=", icon: "mdi-equal" },
        { operator: ">", icon: "mdi-greater-than" },
        { operator: "<", icon: "mdi-less-than" },
        { operator: "!=", icon: "mdi-not-equal" },
        { operator: "CONTAINS" },
        { operator: "STARTS WITH" },
        { operator: "ENDS WITH" },
        { operator: "BETWEEN" },
      ],
      sqlLinkValues: ["AND", "OR"],
      linkSQL: "AND",
      DATES,
      statusMenuItems: [
        "SELL",
        "ALLOCATE",
        "SOLD",
        "EXPIRE",
        "INBOUND",
        "IN-CY",
        "OUT",
      ],
      flaggedValues: ["true", "false"],
      show: false,
      menuDates: [],
      header: header.slice(1),
    };
  },
  watch: {
    // showFilterModal() {
    //   this.filterName = this.show ? this.filterName : "";
    //   this.show = this.showFilterModal;
    // },
  },
  computed: {
    ...mapGetters(["getItemsPerPage"]),
    headerTitle() {
      return this.header.map((value) => value.title);
    },
    operatorValues() {
      return this.operatorsValue.map((operator) => operator.operator);
    },
  },
  methods: {
    ...mapMutations(["setInventoryFilteredColumnFlag", "setOptionsTable"]),
    ...mapActions([
      "filteredList",
      "getSavedFiltered",
      "addNewFilter",
      "updateDataTable",
      "saveFilterData",
    ]),
    async saveFilter({ option, name }) {
      this.show = false;

      let response = await this.saveFilterData({
        option,
        name,
        filters: this.filters,
        id: this.filterInfo.id,
      });

      if (response) this.$emit("updateFilterInfo", name);
      else this.show = true;
    },
    iconOperator(operatorValue) {
      return this.operatorsValue.find(
        (operator) => operator.operator === operatorValue,
      ).icon;
    },
    showModal(show) {
      this.show = show;
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    filteredInventoryByColumn() {
      this.setInventoryFilteredColumnFlag(true);
      this.updateDataTable({ filters: this.filters });
    },
    removeFilter(index) {
      this.$emit("removeFilter", index);
    },
    filterSelected(filter) {
      let filterData = this.header.filter(
        (value) => value.title == filter.title,
      );
      filter.field = filterData[0].value;
      filter.title = filterData[0].title;
    },
    selectOperator(filter) {
      filter.operator = filter.operator.operator;
    },
    addFilter() {
      this.$emit("addFilter", {
        values: this.header[0],
        operator: this.operatorsValue[0].operator,
        conector: this.sqlLinkValues[0],
      });
    },
  },
};
</script>

<style>
.btn-close {
  background-color: transparent !important;
  /* min-width: fit-content !important; */
}
.input-filter {
  font-size: small;
  text-align: center !important;
}
.btn-delete {
  min-width: fit-content !important;
  background-color: transparent !important;
}
.w-100 {
  width: 100%;
}
.filter-panel {
  width: 35%;
  position: fixed;
  background-color: #1e1e1e;
  right: 0;
  z-index: 3;
  align-content: center;
  justify-content: center;
}
/* .v-select.v-text-field input {
  display: none;
} */
.v-select__selections {
  justify-content: center;
}
.inside-container {
  border: thin solid #20212e;
  border-top: none;
  margin: 0% 5% 0px 5%;
  height: 60%;
  width: 100%;
}

.select-filter {
  font-size: small;
  margin: 0 0px !important;
  min-height: 36px !important;
}

.select-filter label {
  font-size: small;
}
.row-filter {
  background-color: blue;
}

.btn-bottom {
  width: 100%;
}
.start-button {
  margin: 5% 5% 0px 5%;
  border: thin solid #20212e;
  border-bottom: none;
}
</style>
