<template>
  <modal title="Sort" width="60%" :show="show">
    <template v-slot>
      <v-row class="ma-0 pa-1 border-bottom-primary">
        <v-col cols="2" class="pa-1">
          <v-btn
            color="dark-blue"
            class="lighten-2 elevation-5"
            small
            block
            @click="addSortField(header[0])"
          >
            <v-icon dark small>mdi-plus</v-icon> Add new
          </v-btn>
        </v-col>
        <v-col cols="2" class="pa-1">
          <v-btn
            color="dark-blue"
            class="lighten-2 elevation-5"
            small
            block
            @click="removeSortField"
          >
            <v-icon small> mdi-close </v-icon>
            Delete
          </v-btn>
        </v-col>
        <v-col cols="2" class="pa-1">
          <v-btn
            color="dark-blue"
            class="lighten-2 elevation-5"
            small
            block
            @click="addSortField(header[0], true)"
          >
            <v-icon dark small>mdi-content-copy</v-icon> Copy row
          </v-btn>
        </v-col>
        <v-divider vertical class="border-color-primary"></v-divider>
        <v-col cols="1" class="pa-1">
          <v-btn
            :disabled="rowSelected === 0"
            color="dark-blue"
            class="lighten-2 elevation-5"
            small
            block
            @click="moveRow('up')"
          >
            <v-icon dark small>mdi-menu-up</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1" class="pa-1">
          <v-btn
            :disabled="rowSelected === sortFields.length - 1"
            color="dark-blue"
            class="lighten-2 elevation-5"
            small
            block
            @click="moveRow('down')"
          >
            <v-icon dark small>mdi-menu-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row justify="center" class="pt-5 ma-0">
        <multi-sort-table
          :sortItems="sortFields"
          :selectColumns="header"
          :selectOrder="selectOrder"
          :rowSelected="rowSelected"
          @setRowSelected="setRowSelected"
        ></multi-sort-table>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn class="elevation-5" color="primary" text @click="save">
        Save
      </v-btn>
      <v-btn class="elevation-5" color="primary" text @click="cancel">
        cancel
      </v-btn>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { header } from "@/helpers/mocks/inventory.js";
import MultiSortTable from "@/components/Inventory/MultiSortTable";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    Modal,
    MultiSortTable,
  },
  props: ["show"],

  data() {
    return {
      caseSensitive: false,
      sortFields: [],
      selectOrder: ["A to Z", "Z to A"],
      rowSelected: -1,
      header: header.slice(1),
    };
  },
  watch: {
    show() {
      if (this.show && !this.sortFields.length) {
        this.addSortField(this.header[0]);
      }
    },
  },
  methods: {
    ...mapActions(["sortInventoryDataFiltered"]),
    ...mapMutations(["setSnackbar"]),
    moveRow(move) {
      let row = this.sortFields.splice(this.rowSelected, 1)[0];
      this.rowSelected =
        move == "up" ? this.rowSelected - 1 : this.rowSelected + 1;
      this.sortFields.splice(this.rowSelected, 0, row);
    },
    removeSortField() {
      this.sortFields.splice(this.rowSelected, 1);

      this.rowSelected =
        this.rowSelected < this.sortFields.length
          ? this.rowSelected
          : this.rowSelected - 1;
    },
    setRowSelected(index) {
      this.rowSelected = index;
    },
    addSortField({ title, value }, copy) {
      this.rowSelected = this.rowSelected + 1;
      copy
        ? this.sortFields.splice(
            this.rowSelected,
            0,
            Object.assign({}, this.sortFields[this.rowSelected - 1]),
          )
        : this.sortFields.splice(this.rowSelected, 0, {
            column: { title, value },
            order: { title: this.selectOrder[0] },
          });
    },
    save() {
      this.sortInventoryDataFiltered({
        sort: this.sortFields,
      });
      this.$emit("cancelModal", false);
      this.setSnackbar({
        text: "Data sorted",
        status: "success",
      });
      this.$emit("multiSortEvent", this.sortFields);
    },
    cancel() {
      this.$emit("cancelModal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.border-color-primary {
  border: solid thin #3284f4;
  //   border-color: #3284f4;
}
.border-bottom-primary {
  border-bottom: solid thin #3284f4;
}
</style>
