<template>
  <v-row class="pa-0 ma-0 fill-height align-content-start" style="width: 100%">
    <v-col cols="12" class="pa-0 ma-0 fill-height" fluid>
      <!-- :style="{ maxHeight: '600px' }" -->
      <pagination :filters="filters" />
      <Table
        :items="items"
        :header="header"
        :switchColumns="switchColumns"
        :addingRow="addingRow"
        :cancelFlag="cancelFlag"
        :saveFlag="saveFlag"
        :expandedTable="expandedTable"
        :tabTitle="tabTitle"
        :filters="filters"
      >
        <template v-if="expandedTable" v-slot:expandedTable="{ item }">
          <Table
            :items="[item]"
            :header="header_expanded"
            :switchColumns="false"
            :expandedTable="false"
          />
        </template>
      </Table>
    </v-col>
  </v-row>
</template>

<style scoped>
/* >>> td {
  max-width: 1000px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { defaultItemObject } from "@/helpers/mocks/inventory.js";

import Pagination from "@/components/Inventory/Pagination.vue";
import Table from "@/components/Inventory/Grid/Table/Table.vue";

export default {
  props: [
    "header",
    "addingRow",
    "cancelFlag",
    "saveFlag",
    // "selectedRows",
    "header_expanded",
    "expandedTable",
    "switchColumns",
    "filters",
    "tabTitle",
  ],
  components: { Table, Pagination },
  data() {
    return {
      expandedData: [],
      items: [],
    };
  },
  computed: {
    ...mapState(["inventoryDataFiltered"]),
    ...mapState({
      selectedRows: (state) => state.moduleInventory.selectedRows,
    }),
  },
  mounted() {
    if (this.inventoryDataFiltered.length) {
      this.items = this.inventoryDataFiltered.map((item) => {
        // this.selectedRows.indexOf(item.id) > -1;
        let copyItem = { ...item };
        copyItem["select"] = this.selectedRows.includes(item.id);
        // item["select"] = false;
        return Object.assign({}, copyItem);
      });
    } else {
      this.items = [];
    }
  },
  watch: {
    cancelFlag() {
      this.resetData();
    },
    async saveFlag() {
      this.saveItems();
    },
    addingRow() {
      if (this.addingRow) this.addRow();
    },

    inventoryDataFiltered() {
      if (this.inventoryDataFiltered.length) {
        this.items = this.inventoryDataFiltered.map((item) => {
          let copyItem = { ...item };
          copyItem["select"] = this.selectedRows.includes(item.id);

          return Object.assign({}, copyItem);
        });
      } else {
        this.items = [];
      }
    },
  },
  methods: {
    ...mapMutations([
      "setTotalItems",
      "moduleInventory/setSelectedRows",
      "moduleInventory/addRowSelect",
    ]),
    ...mapActions(["save", "updateDataTable"]),
    resetData() {
      this.items = this.inventoryDataFiltered.map((item) =>
        Object.assign({}, item),
      );
    },

    addRow() {
      let id = `${
        this.items.length > 0
          ? Number(this.items[this.items.length - 1].id) +
            Math.floor(Math.random() * 50000000) +
            10000000
          : 1 + Math.floor(Math.random() * 50000000) + 10000000
      }`;
      let newItem = { ...defaultItemObject, newItem: true };
      newItem.id = id;
      this.items.push(newItem);

      this.setTotalItems(this.items.length);
      this["moduleInventory/addRowSelect"](id);
    },
    async saveItems() {
      await this.save({ items: this.items });
      this.updateDataTable({ filters: this.filters });
    },
  },
};
</script>
