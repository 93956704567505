<template>
  <v-card class="pa-0">
    <v-row class="pa-0 ma-0" justify="space-around">
      <v-col class="dark_blue elevation-5 ma-1">
        <v-container v-html="previewHtml" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["previewHtml"],
};
</script>

<style></style>
