<template>
  <NestedMenu
    v-if="header.value === 'Status'"
    :isInputActive="isInputActive"
    :model="item"
    :name="item[header.value]"
    :menu-items="statusMenuItems"
    :clicked="item.select"
    @menuClick="onMenuItemClick"
    @addInput="addInputNestedMenu"
    @saveInput="saveInput"
  />

  <date-picker
    v-else-if="ENABLE_DATES.includes(header.value)"
    :data="item"
    :keyData="header.value"
    emitEvent="valueText"
    @valueText="valueText(item, header.value)"
    :clicked="item.select"
  />

  <v-text-field
    v-else
    v-model="item[header.value]"
    outlined
    class="ma-0 rounded-0"
    background-color="grey darken-4"
    :class="isInputDisable ? 'input-disable' : ''"
    full-width
    hide-details
    dense
    :dark="true"
    :readonly="isInputDisable"
    single-line
    @input="valueText(item, header.value)"
  >
  </v-text-field>
</template>

<script>
import NestedMenu from "@/components/Inventory/NestedMenu.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { table_mixin } from "@/plugins/mixin-inventory.js";
import { DATES, ENABLE_DATES } from "@/helpers/mocks/dates.js";
import { nestedMenuData } from "@/helpers/mocks/inventory.js";
export default {
  props: ["header", "item", "index", "items", "expandedTable", "filters"],
  mixins: [table_mixin],

  components: {
    NestedMenu,
  },
  async created() {
    if (this.header.value === "Status") {
      this.statusMenuItems[2].menu.data = [];
      this.statusMenuItems[4].menu.data = [];
      this.organizations.forEach((org) => {
        if (org.id !== null && org.name && org.enabled == "Y") {
          this.statusMenuItems[2].menu.data.push(
            {
              ...org,
            },
            { isDivider: true },
          );

          this.statusMenuItems[4].menu.data.push(
            {
              ...org,
            },
            { isDivider: true },
          );
        }
      });
      this.statusMenuItems[2].menu.data.push({ isBtn: true });
      this.statusMenuItems[4].menu.data.push({ isBtn: true });
    }
  },
  data() {
    return {
      ENABLE_DATES,
      DATES,
      subStatus: [],
      isInputActive: { 2: false, 4: false },
      statusMenuItems: nestedMenuData,
      // flaggedInstructionsByEquipmentIDData: false,
    };
  },
  computed: {
    ...mapState(["organizations"]),
    ...mapState({
      selectedRows: (state) => state.moduleInventory.selectedRows,
    }),
    ...mapGetters(["getInventoryFormatData"]),
  },
  watch: {
    organizations() {
      this.statusMenuItems[2].menu.data = [];
      this.statusMenuItems[4].menu.data = [];
      this.organizations.forEach((org) => {
        if (org.id !== null && org.name && org.enabled == "Y") {
          this.statusMenuItems[2].menu.data.push(
            {
              ...org,
            },
            { isDivider: true },
          );

          this.statusMenuItems[4].menu.data.push(
            {
              ...org,
            },
            { isDivider: true },
          );
        }
      });
      this.statusMenuItems[2].menu.data.push({ isBtn: true });
      this.statusMenuItems[4].menu.data.push({ isBtn: true });
    },
  },
  methods: {
    ...mapActions(["saveOrg"]),

    onMenuItemClick({ item, model }) {
      let subItem = item;
      if (subItem.nameParent) {
        //with sub menus!
        subItem.model.Status = subItem.nameParent;
        subItem.model.group = subItem.item.id;
        subItem.model.allocated_to_entity = subItem.item.id;
        subItem.model["BuyerParty"] = subItem.item.name;
        subItem.model["BuyerPartyId"] = subItem.item.id;

        if (
          subItem.model.Status === "ALLOCATE" ||
          subItem.model.Status === "2"
        ) {
          subItem.model["AllocatedToVisibleEntity_id"] = subItem.item.id
            ? subItem.item.id
            : null;
        }

        if (subItem.model.Status === "SOLD" || subItem.model.Status === "3") {
          subItem.model["SoldToVisibleEntity_id"] = subItem.item.id
            ? subItem.item.id
            : null;
        }
      } else {
        //without sub menus!
        model.Status = item.name;
        model.group = "";
        model["BuyerParty"] = "";
      }

      this.changeStatuses(model);
    },
    changeStatuses(model) {
      this.selectedRows.map((row) => {
        this.items.map((item, index) => {
          if (item.id === row) {
            item["Status"] = model["Status"];
            if (item["Status"] !== "INBOUND") {
              let obj = Object.assign({}, this.items[index]);
              delete obj.substep_descr;
              delete obj.InboundSubStatus_id;

              this.$set(this.items, index, obj);
            }

            if (model.group) item["group"] = model["group"];

            if (model.allocated_to_entity)
              item["allocated_to_entity"] = model["allocated_to_entity"];

            if (model.BuyerParty) item["BuyerParty"] = model["BuyerParty"];

            if (model.BuyerPartyId)
              item["BuyerPartyId"] = model["BuyerPartyId"];

            if (model.AllocatedToVisibleEntity_id)
              item["AllocatedToVisibleEntity_id"] =
                model["AllocatedToVisibleEntity_id"];

            if (model.SoldToVisibleEntity_id)
              item["SoldToVisibleEntity_id"] = model["SoldToVisibleEntity_id"];
            this.valueText(item, "Status");
          }
        });
      });
    },
    async saveInput({ save, inputObj, newOrgData, indexMenu }) {
      const index = inputObj[0].$vnode.key;
      if (save && newOrgData !== "") {
        //if save === true... send the org into the dbb
        this.saveOrg({ externalName: newOrgData });
      } else {
        this.statusMenuItems[indexMenu].menu.data.splice(index, 2);
      }
      this.isInputActive[indexMenu] = false;
    },
    addInputNestedMenu(index) {
      const thirdLastIndex = this.statusMenuItems[index].menu.data.length - 2;
      this.statusMenuItems[index].menu.data.splice(
        thirdLastIndex,
        0,
        { isDivider: true },
        {
          isInput: true,
        },
      );
      this.isInputActive[index] = true;
    },
  },
};
</script>

<style></style>
