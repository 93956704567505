<template>
  <v-data-table
    :headers="headerData"
    :items="sortItems"
    dense
    height="100%"
    hide-default-footer
    disable-pagination
    class="elevation-5 ma-0 pa-0 text--md rounded-0 multi-sort-table"
  >
    <template v-slot:item="{ item, index }">
      <tr
        :style="rowSelected === index ? 'background-color: #3284f4' : ''"
        @click="selectRow(index)"
      >
        <td
          v-for="(header, key) in headerData"
          :key="key"
          class="text-center pa-0 cell-border"
        >
          <v-row class="pa-0 ma-0">
            <v-col
              cols="3"
              v-if="header.value === 'column'"
              class="pa-0 ma-0 mr-2"
            >
              <v-subheader
                v-if="index === 0"
                class="pa-0 pl-1"
                :style="
                  rowSelected === index
                    ? 'color: #262737; font-weight:bolder'
                    : ''
                "
              >
                Sort by
              </v-subheader>
              <v-subheader
                v-else
                class="pa-0 pl-1"
                :style="
                  rowSelected === index
                    ? 'color: #262737; font-weight:bolder'
                    : ''
                "
              >
                Then by
              </v-subheader>
            </v-col>
            <v-col cols="8" class="pa-0 ma-0 d-flex">
              <v-select
                :items="selectData[header.value]"
                dense
                solo
                full-width
                background-color="#20212E"
                hide-details
                class="select-filter lighten-2 pa-0 rounded-0 ma-auto elevation-5 text-center"
                v-model="item[header.value].title"
                @input="columnSelected(item[header.value])"
              ></v-select>
            </v-col>
          </v-row>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["sortItems", "selectColumns", "selectOrder", "rowSelected"],
  computed: {
    headerData() {
      let headerData = [];
      const header = [
        { title: "Column", value: "column" },
        { title: "Order", value: "order" },
      ];
      for (let { value, title } of header) {
        headerData.push({
          text: title,
          align: "left",
          sortable: false,
          value,
          class: "table-header text-capitalize pa-1",
          width: "20%",
        });
      }
      return headerData;
    },
    itemsColumn() {
      return this.selectColumns.map(column => column.title);
    },
    selectData() {
      return { column: this.itemsColumn, order: this.selectOrder };
    },
  },
  data() {
    return {};
  },
  methods: {
    columnSelected(column) {
      console.log(column);
      if (column.value && column.title) {
        let columnData = this.selectColumns.filter(
          value => value.title == column.title,
        );

        column.value = columnData[0].value;
        column.title = columnData[0].title;
      }
    },
    selectRow(index) {
      this.$emit("setRowSelected", index);
    },
  },
};
</script>

<style lang="scss">
.multi-sort-table {
  width: 80%;
  background-color: transparent !important;
  max-height: 50vh;
  th,
  td {
    border: none;
    border-bottom: solid thin #3284f4 !important;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: medium !important;
    font-weight: 200;
  }

  .v-data-table__wrapper > table {
    border-spacing: 0px !important;
  }
}
</style>

<style lang="scss" scoped></style>
